import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.showOrderSalesActionDialog,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showOrderSalesActionDialog) = $event)),
    header: this.header,
    modal: ""
  }, {
    footer: _withCtx(() => [
      (_ctx.order.lis_items && _ctx.order.status != 'C' && _ctx.posShowEditOrder)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            label: "Edit Order",
            icon: "pi pi-pencil",
            class: "p-button-text",
            onClick: _ctx.addOrderToParts
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (_ctx.order.lis_items)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 1,
            label: "View Order",
            icon: "pi pi-search",
            class: "p-button-text",
            disabled: true
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_Button, {
        label: "Include Order",
        icon: "pi pi-dollar",
        class: "p-button-text",
        onClick: _cache[0] || (_cache[0] = ($event: any) => {
          _ctx.addSalesOrderToSummary();
          this.showOrderSalesActionDialog = false;
        }),
        disabled: _ctx.disableShipButton
      }, null, 8, ["disabled"]),
      _createVNode(_component_Button, {
        label: "Cancel",
        icon: "pi pi-times",
        class: "p-button-text",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (this.showOrderSalesActionDialog = false))
      })
    ]),
    default: _withCtx(() => [
      _cache[3] || (_cache[3] = _createElementVNode("div", null, "Which action would you like to perform?", -1))
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}