import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "sales-top-buttons flex justify-content-between flex-wrap" }
const _hoisted_2 = { class: "flex flex-column justify-content-center font-semibold" }
const _hoisted_3 = { class: "block text-xl" }
const _hoisted_4 = {
  key: 0,
  class: "block text-xs text-400"
}
const _hoisted_5 = { class: "flex justify-content-end align-items-center flex-wrap" }
const _hoisted_6 = { class: "right-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1),
      (_ctx.timeAgoUpdated)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Updated " + _toDisplayString(_ctx.timeAgoUpdated) + " ago", 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (_ctx.ShowTableViewButtons)
          ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
              key: 0,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.switchResultView(!_ctx.isResultsListView))),
              class: "split-sales-order-btn",
              icon: _ctx.isResultsListView ? 'pi pi-th-large' : 'pi pi-list',
              outlined: ""
            }, null, 8, ["icon"])), [
              [_directive_tooltip, 
            _ctx.isResultsListView ? 'Kanban Table View' : 'List Table View'
          ]
            ])
          : _createCommentVNode("", true),
        (_ctx.showClearFilterButton)
          ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
              key: 1,
              class: "split-sales-order-btn",
              icon: "pi pi-filter-slash",
              outlined: "",
              "data-test": _ctx.dataTestButtonTag,
              onClick: _ctx.clearFilters
            }, null, 8, ["data-test", "onClick"])), [
              [_directive_tooltip, 'Clear All Filters']
            ])
          : _createCommentVNode("", true),
        (_ctx.showNewButton)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 2,
              class: "new-sales-order-btn",
              label: _ctx.newButtonText,
              icon: "pi pi-plus",
              "data-test": _ctx.dataTestButtonTag,
              onClick: _cache[1] || (_cache[1] = () => _ctx.$emit('newButtonClicked'))
            }, null, 8, ["label", "data-test"]))
          : _createCommentVNode("", true),
        (_ctx.showCopyButton)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 3,
              class: "copy-sales-order-btn",
              label: _ctx.copyButtonText,
              "data-test": _ctx.dataTestButtonTag,
              onClick: _cache[2] || (_cache[2] = () => _ctx.$emit('copyButtonClicked'))
            }, null, 8, ["label", "data-test"]))
          : _createCommentVNode("", true),
        (_ctx.showSplitButton)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 4,
              class: "split-sales-order-btn",
              label: _ctx.splitButtonText,
              "data-test": _ctx.dataTestButtonTag,
              onClick: _cache[3] || (_cache[3] = () => _ctx.$emit('splitButtonClicked'))
            }, null, 8, ["label", "data-test"]))
          : _createCommentVNode("", true),
        (_ctx.showDeleteButton)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 5,
              class: "split-sales-order-btn",
              label: _ctx.deleteButtonText,
              "data-test": _ctx.dataTestButtonTag,
              onClick: _cache[4] || (_cache[4] = () => _ctx.$emit('deleteButtonClicked'))
            }, null, 8, ["label", "data-test"]))
          : _createCommentVNode("", true),
        (_ctx.showExportButton)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 6,
              class: "split-sales-order-btn",
              label: "Export",
              "data-test": _ctx.dataTestButtonTag,
              onClick: _cache[5] || (_cache[5] = () => _ctx.$emit('exportButtonClicked'))
            }, null, 8, ["data-test"]))
          : _createCommentVNode("", true),
        (_ctx.showArchiveButton)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 7,
              class: "split-sales-order-btn",
              label: "Archive",
              "data-test": _ctx.dataTestButtonTag,
              onClick: _cache[6] || (_cache[6] = () => _ctx.$emit('archiveButtonClicked'))
            }, null, 8, ["data-test"]))
          : _createCommentVNode("", true),
        (_ctx.showPrintButton)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 8,
              class: "split-sales-order-btn",
              label: _ctx.printExportButtonText,
              icon: "pi pi-print",
              "data-test": _ctx.dataTestButtonTag,
              onClick: _ctx.printExportData
            }, null, 8, ["label", "data-test", "onClick"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}